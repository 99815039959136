<template>
  <div class="data-page">
    <Transition name="slate" mode="out-in">
      <KeepAlive>
        <Component
          :is="componentName"
          style="width: var(--main-width)"
          :date-arr="dateArr"
          :scroll-height="scrollHeight || 300"
        />
      </KeepAlive>
    </Transition>
  </div>
</template>

<script>
import { ROUTER_NAME } from '@/router'
import TiltSensor from './components/tilt-sensor'

const dateArr = new Date().toString().match(/\w+\s(\w+)\s(\d+)\s(\d+)/i)
export default {
  name: 'image-data',
  components: {
    TiltSensor,
    // 后面可以使用懒加载，因为不是用户首先看到的页面
    // eslint-disable-next-line import/extensions
    TowerCamera: () => import('./components/tower-camera.vue'),
    // eslint-disable-next-line import/extensions
    GroundCamera: () => import('./components/ground-camera.vue'),
    // eslint-disable-next-line import/extensions
    Documents: () => import('./components/documents.vue')
  },
  data() {
    return {
      componentName: 'tilt-sensor',
      dateArr,
      scrollHeight: 0
    }
  },
  mounted() {
    // 无需销毁因为使用缓存，页面不会销毁
    this.$bus.$on('route-nav', (component) => {
      if (this.$route.name !== ROUTER_NAME.DATA) return
      this.componentName = component.componentName
    })

    this.$bus.$emit('mounted', true)
  },
  beforeDestroy() {
    this.$bus.$off('route-nav')
  },
  watch: {
    componentName() {
      this.$nextTick(() => {
        setTimeout(() => {
          const scrollbarDom = document.getElementsByClassName('data-page__scrollbar')

          if (this.scrollHeight === 0 && scrollbarDom.length) {
            this.scrollHeight =
              window.innerHeight - scrollbarDom[0].getBoundingClientRect().top - 30
          }
          // 需要大于等于动画时间
        }, 400)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.data-page {
  ::v-deep &__title {
    width: var(--main-width);
    margin: 0 0 20px;
  }

  ::v-deep .el-form {
    margin-bottom: 30px;
    &-item {
      margin-right: 25px;
      margin-bottom: 0px;
      &__label,
      &__content {
        line-height: 21px;
        height: 21px;
      }

      &__label {
        color: var(--color-black);
        font-weight: 600;
        font-size: 12px;
        padding-right: 25px;
      }
    }
    .el-input__icon,
    .el-input__inner {
      line-height: 21px;
      height: 21px;
    }
    .el-input__suffix {
      right: 0;
    }
    .el-input__icon {
      width: 18px;
    }

    .el-select {
      width: 160px;
      .el-input__inner {
        height: 21px;
        color: var(--color-black);
        border: none;
        border-bottom: 1px solid var(--color-info);
        border-radius: 0px;
        background: rgba(0, 0, 0, 0);
        font-size: 12px;
        font-weight: 600;
        padding: 0;
        &::placeholder {
          color: var(--color-black);
        }
      }
      .el-select__caret.el-icon-arrow-up::before {
        content: '\e78f';
        color: var(--color-black);
      }
    }
  }
}
</style>
